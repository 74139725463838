<script>
import DetailFormGroup from '@/components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import { Button } from 'ant-design-vue'
import { delAction, getAction, postAction, putAction } from '@/command/netTool'

export default {
  name: 'scenicAreasPageSetDetail',
  data() {
    return {
      hotSearchData: [],
      historyData: []
    }
  },
  mounted() {
    this.getInit()
    this.getInit1()
  },
  methods: {
    getInit() {
      getAction('/farmHotLabel/list', {}, '/api').then(res => {
        if (res.code == 200) {
          this.hotSearchData = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getInit1() {
      getAction('/farmSearchRecord/getSearchList', { type: '11' }, '/api').then(res => {
        if (res.code == 200) {
          this.historyData = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    renderExtra() {
      return (
        <div>
          <Button
            v-auth={`farm_hot_label_edit`}
            type="primary"
            ghost
            style={{ borderRadius: '5px' }}
            onClick={this.handleAdd}
          >
            + 添加
          </Button>
        </div>
      )
    },
    renderView(item, index, is_show, type, auth) {
      return (
        <div class="hot-search-box" key={index}>
          <span>{item.searchContent || item.labelName}</span>
          {is_show && (
            <img
              v-auth={`farm_hot_label_edit`}
              class="img1"
              src={require('../../../assets/edit.png')}
              onClick={() => this.handleEdit(item)}
            />
          )}
          <a-popconfirm
            title="确定要删除吗？"
            ok-text="确定"
            cancel-text="取消"
            onConfirm={() => this.handleConfirm(item, type)}
          >
            <img v-auth={auth} class="img2" src={require('../../../assets/del.png')} />
          </a-popconfirm>
        </div>
      )
    },
    handleAdd() {
      apiTool.showDrawer({
        title: '添加',
        width: '600px',
        form: [
          {
            name: '名称',
            key: 'labelName',
            type: 'input'
          }
        ],
        success: ({ data, setHidden }) => {
          if (!data.labelName) {
            this.$message.warning('请完成填写')
            return false
          }

          postAction('/farmHotLabel/save', { labelName: data.labelName }, '/api').then(res => {
            if (res.code == 200) {
              this.$message.success('操作成功')
              setHidden()
              this.getInit()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    handleEdit(item) {
      apiTool.showDrawer({
        title: '编辑',
        width: '600px',
        formData: { ...item },
        form: [
          {
            name: '名称',
            key: 'labelName',
            type: 'input'
          }
        ],
        success: ({ data, setHidden }) => {
          if (!data.labelName) {
            this.$message.warning('请完成填写')
            return false
          }

          putAction('/farmHotLabel/update', { id: item.id, labelName: data.labelName }, '/api').then(res => {
            if (res.code == 200) {
              this.$message.success('操作成功')
              setHidden()
              this.getInit()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    handleConfirm(item, type) {
      if (type == 'hot') {
        delAction('/farmHotLabel/delete?id=' + item.id, {}, '/api').then(res => {
          if (res.code == 200) {
            this.$message.success('操作成功')
            this.getInit()
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        delAction('/farmSearchRecord/deleteByName?type=11&name=' + item.searchContent, {}, '/api').then(res => {
          if (res.code == 200) {
            this.$message.success('操作成功')
            this.getInit1()
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    getForm4() {
      return {
        render: () => {
          return (
            <a-card title="热搜" extra={this.renderExtra()}>
              <div class="hot-search">
                {this.hotSearchData.map((item, index) => {
                  return this.renderView(item, index, true, 'hot', 'farm_hot_label_delete')
                })}
              </div>
              <div class="hot-history">历史搜索</div>
              <div class="hot-search">
                {this.historyData.map((item, index) => {
                  return this.renderView(item, index, false, 'history', 'farm_search_record_delete')
                })}
              </div>
            </a-card>
          )
        }
      }
    },
    getFoot() {
      return {
        left: [
          {
            name: '返回',
            onClick: () => {
              this.$router.back()
            }
          }
        ]
      }
    }
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} data={[this.getForm4()]} />
  }
}
</script>
<style lang="less" scoped>
.hot-history {
  margin: 15px 0;
}

.hot-search {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  .hot-search-box {
    border: 1px solid #e8eaec;
    padding: 3px 5px 3px 12px;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0 10px 10px 0;

    span {
      margin-right: 15px;
    }

    img {
      cursor: pointer;
    }
  }
}
</style>
